body {
    margin: 0;
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    height: 100%;
}

a {
    text-decoration: none;
}

#root {
    //background-color: #fff;
}

.gm-style .gm-style-iw-d {
    overflow: hidden !important;
    max-height: none !important;
}

.gm-style .gm-style-iw-c {
    padding: 0 !important;
    max-height: none !important;
}

.gm-style .gm-style-iw-c:after {
    top: -1px;
}

[style*='--aspect-ratio'] > :first-child {
    width: 100%;
}

[style*='--aspect-ratio'] > img {
    height: auto;
}

@supports (--custom: property) {
    [style*='--aspect-ratio'] {
        position: relative;
    }
    [style*='--aspect-ratio']::before {
        height: 0;
        content: '';
        display: block;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*='--aspect-ratio'] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

button.gm-ui-hover-effect {
    visibility: hidden;
}
